import React, {ComponentPropsWithoutRef} from 'react'
import Button from './Button'

type ButtonProps = ComponentPropsWithoutRef<typeof Button>

export default function ActionButton({onClick, ...otherProps}: RequiredKeys<ButtonProps, 'onClick'>) {
    const props: ButtonProps = {
        type: 'button',
        ...otherProps
    }

    if (onClick != undefined) {
        props.onClick = ev => {
            ev.preventDefault()
            onClick(ev)
        }
    }

    return <Button {...props} />
}
