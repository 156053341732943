import {isFunction as _isFunc} from 'lodash'

export function isObject(obj: any): obj is object {
    return obj != null && typeof obj === 'object'
}

export function isPlainObject(obj: any): obj is Record<PropertyKey, unknown> {
    if(!isObject(obj)) return false
    const proto = Object.getPrototypeOf(obj)
    return proto === null || proto === Object.prototype
}

export const isFunction: ((obj: any) => obj is AnyFn) = _isFunc

/**
 * Checks if the object is a *valid* date.
 */
export function isDate(x: any): x is Date {
    return Object.prototype.toString.call(x) === "[object Date]" && !isNaN(x)
}
