if(__USE_SERVICE_WORKER__) {
    if('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/assets/service-worker.js').then(registration => {
                if(__DEV__) console.info('Service worker registered: ', registration)
            }).catch(registrationError => {
                console.error('Service worker registration failed: ', registrationError)
            })
        })
    }
}

export {}
