import $ from 'jquery'
import 'jquery-validation'
import './jquery-ui-1.12.1.custom'
import 'jquery-ui-touch-punch'
import jqMousewheel from 'jquery-mousewheel'
import 'jquery.transit'
import './jquery.scrollIntoView'
import 'trumbowyg'
import trumbowygIcons from 'trumbowyg/dist/ui/icons.svg'



// import './datatables.custom'

// require('datatables.net')(globalThis, $)  // FIXME: how to `import` this?
// // require('datatables.net-dt/css/jquery.dataTables.css');
// require('datatables.net-responsive')
// require('./datatables.custom')

// require('select2/dist/js/select2.full');
import 'select2'
import './jqext'  // must be at below other imports

globalThis.$ = globalThis.jQuery = $
jqMousewheel($)
$.trumbowyg.svgPath = trumbowygIcons

export default $
