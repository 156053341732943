/**
 * Standard error codes. Should match services/php/app/Exceptions/ErrorCode.php.
 */
export const enum ErrorCode {
    /**
     * Not logged in.
     * @see services/php/app/Http/Middleware/Authenticate.php
     */
    UNAUTHENTICATED = 'unauthenticated',
    /**
     * Invalid CSRF token.
     */
    TOKEN_MISMATCH = 'token_mismatch',
    /**
     * Invalid Google reCAPTCHA token.
     */
    INVALID_RECAPTCHA = 'invalid_recaptcha',
    /**
     * Special case validation error.
     */
    VALIDATION_ERROR = 'validation_error',
    /**
     * Laravel validation failed.
     */
    VALIDATION_EXCEPTION = 'validation_exception',
    /**
     * Access denied/forbidden/unauthorized.
     * Not to be confused with unauthenticated.
     */
    ACCESS_DENIED = 'access_denied',
}
