import React, {
    Component,
    ComponentPropsWithoutRef,
    ComponentPropsWithRef,
    ElementRef,
    ElementType,
    ForwardRefExoticComponent,
    ReactElement,
    ReactNode
} from 'react'
import _ from 'lodash'
import * as kb from "../kb"
import * as ReactDOM from "react-dom"
import * as RDS from 'react-dom/server'
import setIn from 'lodash/fp/set'
import update, {Spec} from 'immutability-helper2'
import type {Argument as ClassValue} from 'classnames'
import cn from 'classnames'
import PageFragment from '../pages/app_page'
import ConfirmDialog from '../components/ConfirmDialog'
import {EMPTY_ARRAY, NOOP} from './constants'
import {Resolvable, Resolved, resolveValue} from '../util/resolvable'
import {getElement} from '../util/dom-selector'
import {isPlainObject} from '../util/is-type'
import {legacyFilterMap} from '../util/collections'
import type * as DataTables from 'datatables.net'

export {default as mergeAttrs} from 'merge-attrs';

export type OptionTuple<T=string|number> = [value:T,label:string]
export type OptionTuples<T=string|number> = OptionTuple<T>[]
export type SelectOptions<T=string|number> = OptionTuples | ReadonlyArray<T> | Readonly<Record<string,T>>

export function selectOptions(options?: Readonly<SelectOptions>): ReadonlyArray<JSX.Element> {
    if (!options) return EMPTY_ARRAY

    const keys = new Map;

    function makeOpt(value: string|number, children: ReactNode) {
        let keyCount = keys.get(value);
        let key = value;
        if (keyCount !== undefined) {
            ++keyCount;
            key = `${value}__${keyCount}`
        } else {
            keyCount = 1;
        }
        keys.set(value, keyCount);
        return <option key={key} value={value}>{children}</option>
    }

    if (Array.isArray(options)) {
        if (!options.length) {
            return EMPTY_ARRAY;
        }
        if (Array.isArray(options[0])) {
            return (options as OptionTuple[]).map(opt => makeOpt(opt[0], opt[1]));
        }
        if (typeof options[0] === 'object') {
            console.error("Malformed options array", options);
        }
        return (options as Array<number|string>).map(opt => makeOpt(opt, opt));
    }

    return _.map(options, (children, value) => makeOpt(value, children));
}

// export function radioButtons(buttons) {
//     if(Array.isArray(buttons)) {
//         return buttons.map(btn => <RadioButton key={btn[0]} value={btn[0]}>{btn[1]}</RadioButton>);
//     }
//     return _.map(buttons, (label, value) => <RadioButton key={value} value={value}>{label}</RadioButton>);
// }

export function cloneWithProps<TChild extends ReactElement,TProps>(children: TChild|TChild[], props: TProps|((c:TChild,i:number)=>TProps)) {
    const elements = React.Children.toArray(children).filter(x => x) as TChild[]
    if (_.isFunction(props)) {
        let i = 0;
        return elements.map(child => React.cloneElement(child, props(child, i++)));
    } else {
        return elements.map(child => React.cloneElement(child, props));
    }
}

// function json(value) {
//     return JSON.stringify(value, null, 2);
// }

/**
 * Takes a function that accepts 4 positional args and returns a React component.
 */
export function displayReact(renderFunc: (...args:any[])=>ReactElement) {
    return {
        display: (...args: any[]) => RDS.renderToStaticMarkup(renderFunc(...args)),
    }
}

interface DataTableCellMetaSettings {
    row: number
    col: number
    settings: unknown
}

/**
 * @see https://datatables.net/reference/option/columns.render
 */
interface DataTableDisplayProps<T=any> {
    data: T
    type: 'display'
    row: Record<string,any>
    meta: DataTableCellMetaSettings
}

/**
 * Takes a React component with props {data,type,row,meta}.
 */
export function displayReact2<T=any>(Component: ElementType<DataTableDisplayProps<T>>): DataTables.ConfigColumns['render'] {
    return {
        display: (data: T, type: 'display', row: Record<string,any>, meta: DataTableCellMetaSettings) => {
            return RDS.renderToStaticMarkup(React.createElement(Component, {data, type, row, meta}));
        }
    }
}

export type UpdateSpec = Spec<Record<string,any>>

// type ComponentState<C> = C extends React.Component<any, infer S> ? S : Record<string,never>;
// export function updateState<C extends React.Component>(this: C, updates: Spec<ComponentState<C>>, callback?: () => void) {

export function updateState(this: React.Component, updates: UpdateSpec, callback?: () => void) {
    this.setState(state => update(state, updates), callback);
}


function targetValue(ev: { target: { value: string } }) {
    return ev.target.value;
}

function targetChecked(ev: { target: { checked: boolean } }) {
    return ev.target.checked;
}



interface LinkStateRet<TValue> {
    value: TValue
    onChange: AnyEventHandler
}


export function linkState(this: React.Component, name: _.PropertyPath, valueGetter: AnyFn = targetValue, defaultValue = ''): LinkStateRet<string> {
    return {
        value: getState.call(this, name, defaultValue),
        onChange: (...args: any[]) => {
            setState.call(this, name, valueGetter(...args));
        },
    }
}

export function forwardState(obj: React.Component<EmptyObject,UnknownObject>) {
    // TODO: add prefix option
    return {
        linkState: linkState.bind(obj),
        linkChecked: linkChecked.bind(obj),
        updateState: updateState.bind(obj),
        setState: setState.bind(obj),
        getState: getState.bind(obj),
        state: obj.state,
    }
}

export type ForwardedState = ReturnType<typeof forwardState>

export function getState(this: React.Component, name: _.PropertyPath, defaultValue: any = '') {
    return _.get(this.state, name, defaultValue);
}

export function setState(this: React.Component, name: _.PropertyPath, value: any) {
    return this.setState(setIn(name, value));
}

export function linkChecked(this: React.Component, name: _.PropertyPath, valueGetter = targetChecked, defaultChecked: boolean = false) {
    return {
        checked: kb.toBool(_.get(this.state, name, defaultChecked)),
        onChange: (...args: any[]) => {
            let value = kb.toBool(valueGetter(...args));
            this.setState(setIn(name, value))
        },
    };
}

// function argEq(a, b) {
//     if(typeof a === 'object' && typeof b === 'object') {
//         return shallowEqual(a,b);
//     }
//     return Object.is(a,b);
// }

function arrayEq<T>(arr1: T[], arr2: T[]): boolean {
    return arr1.length === arr2.length && arr1.every((v, i) => _.isEqual(v, arr2[i]));
}

export function createSelector<TComponent extends React.Component>(this: TComponent, deps: Array<PropertyKey|ComponentPropGetter>, getter: AnyFn) {
    let lastArgs: any, lastValue: any;
    return () => {
        const args = legacyFilterMap.call(deps, f => _.isFunction(f) ? f.call(this, this.state, this.props, this.context) : this[f as keyof TComponent]);
        if (lastArgs && arrayEq(lastArgs, args)) {
            return lastValue;
        }
        lastArgs = args;
        lastValue = getter(...args);
        return lastValue;
    };
}

type ComponentPropGetter = ((this: React.Component, state: any, props: any, context: unknown) => any)

/**
 * @deprecated Use either {@link lazyPropSingle} or {@link lazyPropMulti}
 */
export function lazyProp<TComponent extends React.Component>(this: TComponent, name: string, deps: any, getter?: any): void {
    Object.defineProperty(this, name, {
        get: getter
            ? createSelector.call(this, deps, getter)
            : () => deps.call(this, this.state, this.props, this.context)
    });
}

export function lazyPropSingle(this: React.Component, name: string, getter: ComponentPropGetter): void {
    Object.defineProperty(this, name, {
        get: () => getter.call(this, this.state, this.props, this.context)
    });
}

export function lazyPropMulti(
    this: React.Component,
    name: string,
    deps: Array<PropertyKey|ComponentPropGetter>,
    getter: (...args: any[])=>any
): void {
    Object.defineProperty(this, name, {
        get: createSelector.call(this, deps, getter)
    });
}

const DEBOUNCE = 400

export function lazyAsyncProp<TComponent extends React.Component>(this: TComponent, name: string, deps: Array<PropertyKey|ComponentPropGetter>, getter: (...args: any[]) => Promise<any>): void {
    let lastToken: any = null
    let lastArgs: any = null
    let lastValue: any = undefined
    let lastInvoke: number = -DEBOUNCE
    let timer: any = null

    Object.defineProperty(this, name, {
        get() {
            if(timer) {
                clearTimeout(timer)
                timer = null
            }

            const args = deps.map(d => _.isFunction(d) ? d.call(this, this.state, this.props, this.context) : this[d])

            if (lastArgs && arrayEq(lastArgs, args)) {
                return lastValue
            }

            const now = Date.now()
            const diff = now - lastInvoke
            lastInvoke = now
            if(diff < DEBOUNCE) {
                timer = setTimeout(() => {
                    this.forceUpdate()
                }, DEBOUNCE)
                return lastValue
            }

            lastArgs = args
            const promise = getter(...args)

            if (!promise) {
                if(lastValue !== promise) {
                    lastValue = promise
                    this.forceUpdate()
                }
            } else {
                const token = lastToken = Symbol('CONSISTENCY_TOKEN')

                promise.then(res => {
                    if (token === lastToken && lastValue !== res) {
                        lastValue = res
                        this.forceUpdate()
                    }
                }, () => {
                    if (token === lastToken && lastValue !== null) {
                        lastValue = null
                        this.forceUpdate()
                    }
                })
            }

            return lastValue
        }
    })
}

export function renderFragment(component: React.ReactElement, root: Element|string) {
    const container = getElement(root)
    ReactDOM.render(<React.StrictMode><PageFragment>{component}</PageFragment></React.StrictMode>, container)
    return () => ReactDOM.unmountComponentAtNode(container);
}

export function renderPopup(component: React.ReactElement) {
    return renderFragment(component, document.getElementById('popup-root') || document.body)
}

export function createRef<T>(initialValue: T): {current: T};
export function createRef<T>(): {current: T|null};
export function createRef<T>(initialValue?: T): {current: T|null} {
    return {current: initialValue ?? null}
}

/**
 * @deprecated Use {@link appendPopup}
 */
export function appendComponent(Component: ElementType<{unmount: VoidFn}>, props?: Record<string, any>) {
    const div = document.createElement('div');
    div.style.display = 'contents'
    const ref = createRef<VoidFn>(NOOP)
    document.body.appendChild(div);
    const unmount = () => {
        ref.current()
        document.body.removeChild(div);
    };
    ref.current = renderFragment(<Component {...props} unmount={unmount}/>, div)
    return unmount;
}

export function appendPopup(factory: (unmount:VoidFn)=>React.ReactElement): void {
    const unmount = renderPopup(factory(() => unmount()))
}

export function confirmDialog(message: ReactNode, onOk: VoidFn, onCancel?: VoidFn) {
    // alternatively, we could make this a promise...
    appendComponent(({unmount}) => <ConfirmDialog onCancel={onCancel} close={unmount} onOk={onOk}>{message}</ConfirmDialog>)
}

// export function openModal(content: ReactNode, props: Omit<ModalDialogProps,'onClose'|'children'>) {
//     appendComponent(({unmount}) => <ModalDialog {...props} onClose={unmount}>{content}</ModalDialog>)
// }

// export function confirmDialogPromise(message: ReactNode, onOk: VoidFn) {
//     const p = new Promise((resolve,reject) => {
//         appendComponent(({unmount}) => <ConfirmDialog onCancel={unmount} onOk={resolve} children={message}/>)
//     })
//     p.then(() => {
//         // unmount
//     })
//     return p
// }

// export type ClassValue = string | number | {[className:string]:ClassValue} | ClassValue[] | undefined | null | boolean;
export type {ClassValue}

export type ComponentPropsWithClass<T> = Override<React.ComponentPropsWithoutRef<T>, {
    className?: ClassValue
}>

export type ElementTypeWithClass = ElementType<{ className?: string }> | ElementType<{ className?: ClassValue }>

export type AnyComponent<P=any> = ForwardRefExoticComponent<P>
    | { new (props: P): Component<P> }
    | ((props: P, context?: any) => ReactElement | null)
    | keyof JSX.IntrinsicElements

export function withClass<E extends AnyComponent>(Element: E, fixedClass: ClassValue) {  // : FunctionComponent<Override<React.ComponentPropsWithoutRef<E>, { className?: ClassValue }>>
    return React.forwardRef<ElementRef<E>, ComponentPropsWithClass<E>>(function withClass({className, ...props}, ref) {
        return <Element {...props} ref={ref} className={cn(fixedClass, className)}  />
    })
    // return ({className, ...props}: any) => <El className={classNames([klass, className])} {...props}/>
}

export const styledDiv = (fixedClass: ClassValue) => withClass<'div'>('div', fixedClass)
export const styledSpan = (fixedClass: ClassValue) => withClass<'span'>('span', fixedClass)

type PartialPropsWithoutRef<E extends React.ElementType> = Partial<React.ComponentPropsWithoutRef<E>>

type MergeOptional<TOptions extends object,TDefaults extends Partial<TOptions>> = Override<TOptions, {
    [P in keyof TDefaults]?: TOptions[P]
}>

// https://codesandbox.io/s/ts-react-withdefaultprops-z236h?file=/src/index.tsx
export function withDefaultProps<
    E extends AnyComponent,
    Props=React.ComponentPropsWithoutRef<E>,
    DefaultProps=Resolvable<PartialPropsWithoutRef<E>,[Props]>
>(
    Element: E,
    defaultProps: DefaultProps
): React.ExoticComponent<OptionalKeys<ComponentPropsWithRef<E>, keyof Resolved<DefaultProps>>> {
    return React.forwardRef(function withDefaultProps(props, ref) {
        return <Element {...resolveValue(defaultProps,props)} {...props} ref={ref} />
    })
}

// export function openReactModal(component) {
//     const div = document.createElement('div');
//     document.body.appendChild(div);
//     ReactDOM.render(component)
// }
type MaybeArray<T> = ReadonlyArray<T> | T
export type ChildrenOfType<T extends ElementType> = MaybeArray<ReactElement<ComponentPropsWithoutRef<T>>>

export type ComposedFC<Base extends ElementType, Extension extends Record<string,unknown>={}, DeleteKeys extends PropertyKey=never> = React.FC<Override<ComponentPropsWithoutRef<Base>, Extension, DeleteKeys>>

export type DangerousHtml = { __html: string }

// export type PartialStyle<K extends keyof React.CSSProperties> = Pick<React.CSSProperties, K>
export function isDangerousHtml(x: any): x is DangerousHtml {
    if(!isPlainObject(x)) return false
    return typeof x.__html === 'string'
    // const keys = Object.keys(x)
    // if(keys.length !== 1) return false
    // return keys[0] === '__html'
}
