import {notify} from '../kb';
import {bind} from 'lodash'
import $ from 'jquery'
import {ErrorCode} from '../helpers/ErrorCode'
import {jsonStringify} from '../helpers/jsonSerializable'

/**
 * Returns a jQuery object for the HTML element with the given ID
 */
$.id = function (id: string) {
    return $(document.getElementById(id) ?? []);
};


/**
 * Bind two handlers to the matched elements, to be executed when the mouse pointer enters and leaves the elements.
 *
 * @param handlerIn A function to execute when the mouse pointer enters the element.
 * @param handlerOut A function to execute when the mouse pointer leaves the element and the delay has passed.
 * @param delay Default=400. How long to wait before calling handlerOut (milliseconds).
 */
$.fn.hoverDelay = function (this: JQuery, handlerIn: ((eventObject: JQueryEventObject) => any)|null, handlerOut: (eventObject: JQueryEventObject) => any, delay = 400) {
    let timer: number|undefined;
    this.hover(function (this: JQuery, eventObject: any) {
        clearTimeout(timer);
        if (handlerIn) handlerIn.call(this, eventObject);
    }, function (this: JQuery, eventObject: any) {
        timer = window.setTimeout(bind(handlerOut, this, eventObject), delay); // Function.prototype.bind isn't available in IE8, use lodash compatibility function _.bind
    });
};

$.fn.all = function (selector: any) {
    if (selector === undefined) return this.find('*').addBack();
    return this.filter(selector).add(this.find(selector));
};

$.fn.reverse = Array.prototype.reverse;

$.fn.fadeAndRemove = function (this: JQuery, duration: number|string) {
    return this.fadeOut(duration, function (this: JQuery) {
        $(this).remove();
    });
};

$.fn.serializeObject = function () {
    const o: Record<string,any> = {}
    const a: JQuerySerializeArrayElement[] = this.serializeArray();
    $.each(a, function (this: JQuerySerializeArrayElement) {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

/**
 * @see http://stackoverflow.com/a/12840617/65387
 */
$.ajaxPrefilter(function (options: any, originalOptions: JQueryAjaxSettings, jqXHR: JQueryXHR) {
    const originalErrorHandler = originalOptions.error;

    options.error = $.noop();

    const dfd = $.Deferred();
    jqXHR.done(dfd.resolve);

    jqXHR.fail(function (...args) {
        if (jqXHR.responseJSON?.code  === ErrorCode.UNAUTHENTICATED) {
            notify({
                className: 'warning',
                html: `Your session has expired. Please <a href="/login">log in</a> again.`,
            })
        } else if (jqXHR.responseJSON?.code === ErrorCode.TOKEN_MISMATCH) {
            notify({
                className: 'warning',
                text: `Invalid CSRF token. Your session may have expired; please refresh the page and try again.`,
            })
        } else if (typeof Sentry !== 'undefined') {
            Sentry.setContext('Ajax Prefilter', {
                options: originalOptions,
                data: originalOptions.data,
                status: jqXHR.status,
                args: args,
            });
        }
        if (originalErrorHandler) {
            dfd.fail(originalErrorHandler);
        }
        dfd.rejectWith(jqXHR, args);
    });

    return dfd.promise(jqXHR);
});

$.validator.setDefaults({
    errorClass: 'jqv-error'
});

$.ajaxSetup({
    beforeSend: function (xhr: any, settings: any) {
        if (settings.type !== 'OPTIONS') {
            xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
        }
    }
    // headers: {
    //     'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    // }
});

