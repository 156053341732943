import {Warning} from './helpers/errors'
import {captureSentryError} from './sentry'

function isFunction(x: any): x is AnyFn {
    return Object.prototype.toString.call(x) === '[object Function]';
}

type Module = XOR<{
    __esModule: true
    default: AnyFn
} | AnyFn>

function requireAsync(module: string): Promise<Module> {
    return import(/* webpackPrefetch: true */`./pages/${module}`);
}

export function runAsync(moduleName: string, ...args: any[]) {
    return requireAsync(moduleName).then(module => {
        if (module.__esModule) {
            // if it's an es6 module, then the default function should be exported as module.default
            if (isFunction(module.default)) {
                return module.default(...args);
            }
        } else if (isFunction(module)) {
            // if it's not an es6 module, then the module itself should be the function
            return module(...args);
        }
    }, err => {
        captureSentryError(`Could not import ${moduleName}`, err, null, '67fa6f6b-d8f8-404d-a82f-c2b11a740398', {
            level: 'warning',
            tags: {'module.name': moduleName},
        })
        throw new Warning(`Failed to import page module: ${moduleName}`, {cause: err})
    })
}
