import {times} from 'lodash';
import css from './LoadingDots.less'

interface Props {
    center?: boolean
    height?: number|string
}

export default function LoadingDots({center, height}: Props) {
    let dots = (
        <div className={css.dots}>
            {times(4, i => <div key={i}/>)}
        </div>
    );

    if (center || height != null) {
        dots = <div className={css.dotsCenter} style={{height}}>{dots}</div>;
    }

    return dots;
}
