import css from './GroupBookingDialog.less'
import {ReactNode} from 'react'
import Modal, {ModalProps} from './Modal'

export type ModalDialogProps = Override<ModalProps, {title:ReactNode}, 'dialogClass'>

const ModalDialog: React.FC<ModalDialogProps> = ({title,children,...props}) => (
    <Modal {...props} dialogClass={css.dialog}>
        <div className={css.titleBar}>{title}</div>
        <div className={css.content}>
            {children}
        </div>
    </Modal>
)

export default ModalDialog

