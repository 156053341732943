// Modal.jsx
import React, {Fragment, CSSProperties} from 'react';

import styles from './Modal.less'
// import ReactDOM from 'react-dom';
import classNames from 'classnames';
import ErrorBoundary from '../ErrorBoundary'
import ActionLink from '../ActionLink'

export type ModalProps = {
    /** Can be used to hide the modal instead of unmounting it. */
    isOpen?: boolean;
    /** Action to perform when close button or backdrop is clicked. Should hide or unmount the component. */
    onClose?: VoidFn;
    /** Close the dialog when the backdrop is clicked. */
    closeClick?: boolean;
    width?: CSSProperties['width'];
    minWidth?: CSSProperties['minWidth'];
    maxWidth?: CSSProperties['maxWidth'];
    dialogClass?: string;
    dialogStyle?: CSSProperties;
    animate?: boolean;
    closeButton?: boolean;
};

// Alternatively, we could have dropped the `isOpen` prop and put the logic into didMount/willUnmount... not sure which is better
export default class Modal extends React.PureComponent<ModalProps> {

    static defaultProps = {
        closeClick: false,
        animate: false,
        isOpen: true,
    };

    dialog: any;
    mask: any;
    wrap: any;

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.onOpenChanged();
        }
        this.onUpdate();
    }

    onUpdate = () => {
        if (this.props.animate && this.props.isOpen && this.dialog && this.mask && window.getComputedStyle) {
            const style = window.getComputedStyle(this.dialog);
            Object.assign(this.mask.style, {
                width: parseInt(style.width) + parseInt(style.paddingLeft) + parseInt(style.paddingRight) + parseInt(style.borderLeftWidth) + parseInt(style.borderRightWidth) + 'px',
                height: parseInt(style.height) + parseInt(style.paddingTop) + parseInt(style.paddingBottom) + parseInt(style.borderTopWidth) + parseInt(style.borderBottomWidth) + 'px',
            });
        }
    };

    componentDidMount() {
        this.onOpenChanged(); // can be mounted in open or closed state
        this.onUpdate();
    }

    componentWillUnmount() {
        this.onClose();
    }

    onOpenChanged = () => {
        if (this.props.isOpen) {
            this.onOpen();
        } else {
            this.onClose();
        }
    };

    onOpen = () => {
        if (this.props.onClose && document.addEventListener) {
            document.addEventListener('keydown', this.onEscape);
        }
    };

    onEscape = (ev: any) => {
        if (this.props.isOpen && ev.keyCode === 27) {
            ev.stopPropagation(); // we've handled the event -- don't escape out of other things
            this.props.onClose?.();
        }
    };

    onClose = () => {
        if (document.removeEventListener) {
            document.removeEventListener('keydown', this.onEscape);
        }
    };

    closeClick = (ev: any) => {
        ev.stopPropagation();
        this.props.onClose?.();
    };

    dialogClick = (ev: any) => {
        ev.stopPropagation(); // don't bubble up to the greyOut
    };

    setWrapRef = (n: any) => {
        this.wrap = n;
    };

    setMaskRef = (n: any) => {
        this.mask = n;
    };

    setDialogRef = (n: any) => {
        this.dialog = n;
    };

    render() {
        const {isOpen, children, closeClick, closeButton, onClose, width, minWidth, maxWidth, dialogClass, dialogStyle} = this.props;

        if (!isOpen) {
            return null;
        }

        return (
            <div ref={this.setWrapRef} className={styles.wrap1} onClick={closeClick ? this.closeClick : undefined}>
                <div className={styles.wrap2}>
                    <div className={styles.wrap3}>
                        <div className={styles.wrap4}>
                            <div ref={this.setMaskRef} className={styles.mask}>
                                <div ref={this.setDialogRef} onClick={this.dialogClick}
                                     className={classNames(styles.dialog, dialogClass)}
                                     style={{...dialogStyle, width, minWidth, maxWidth}} role="dialog" aria-modal="true">
                                    {closeButton && onClose ? <ActionLink className={styles.closeBtn}
                                                                 onClick={this.closeClick}>&times;</ActionLink> : null}
                                    <ErrorBoundary>{children}</ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export function ModalTitle({children}) {
//     return <div className={styles.titleBar}>{children}</div>
// }
