import React, {ReactElement, ReactNode, useContext} from 'react'

const TestIdContext = React.createContext<string|undefined>(undefined);
TestIdContext.displayName = "TestId";

/**
 * Adds a prefix to the testid from the current context if available, otherwise returns the id as-is.
 */
export function useTestId(id: string|undefined) {
    const prefix = useContext(TestIdContext);
    if(!id) return undefined;
    if(!prefix) return id;
    return `${prefix}-${id}`;
}

interface TestIdProps {
    prefix: string
    children: ReactNode
}

/**
 * TestId provider. Use around repeated form elements to give them a unique prefix.
 */
export function TestId({prefix, children}: TestIdProps) {
    const testId = useTestId(prefix);
    return <TestIdContext.Provider value={testId}>{children}</TestIdContext.Provider>
}

interface TestIdConsumerProps {
    id?: string
    children: (testId: string|undefined) => ReactElement;
}

/**
 * For class components. Prefer `useTestId` when possible.
 */
export function TestIdConsumer({id, children}: TestIdConsumerProps) {
    return children(useTestId(id))
}
