/**
 * A catchable Error and doesn't log to Sentry.
 */
export class Warning extends Error {
    readonly $isWarning = true

    constructor(message?: string, options?: ErrorOptions) {
        super(message, options);  // 'Error' breaks prototype chain here
        this.name = new.target.name
        Object.setPrototypeOf(this, new.target.prototype);  // restore prototype chain
    }
}
