import React, {ErrorInfo, ReactNode} from 'react';
import css from './ErrorBoundary.less';

export interface Props {
    children: ReactNode;
}

export interface State {
    error: null | Error;
    errorInfo: null | ErrorInfo;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {error: null, errorInfo: null}
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.error) {
            return (
                <div className={css.container}>
                    <h2 className={css.title}>Runtime Error</h2>
                    {__DEV__ && <code className={css.code}>
                        {String(this.state.error)}
                        {this.state.errorInfo?.componentStack ? this.state.errorInfo.componentStack : null}
                    </code>}
                </div>
            );
        }

        return this.props.children;
    }
}
