export function getElementByIdOrThrow<T extends HTMLElement=HTMLElement>(id: string): T {
    const el = document.getElementById(id)
    if(el == null) throw new Error(`Element not found: "${id}"`)
    return el as T
}

export function getElement<T extends Element=Element>(elementSelector: T | string): T {
    if(!elementSelector) {
        throw new Error(`Missing element selector`)
    }
    if(typeof elementSelector === 'string') {
        const el = document.querySelector(elementSelector)
        if(!el) throw new Error(`Could not find React root via CSS selector ${elementSelector}`)
        return el as T
    }
    return elementSelector
}
