export function parseRadix(nbr: string, radix: number, defaultValue: number): number {
    if(nbr == null || nbr === '') return defaultValue
    const val = globalThis.parseInt(nbr, radix)
    return Number.isFinite(val) ? val : defaultValue
}

export function parseInt<TDefault>(nbr: string | number | nil, defaultValue: TDefault): number | TDefault
export function parseInt(nbr: string | number | nil): number | null
export function parseInt(nbr: string | number | nil, defaultValue: any = null) {
    if(nbr == null || nbr === '') return defaultValue
    const val = globalThis.parseInt(nbr as string, 10)
    return Number.isFinite(val) ? val : defaultValue
}

export function parseFloat<TDefault>(nbr: string | number | nil, defaultValue: TDefault): number | TDefault
export function parseFloat(nbr: string | number | nil): number | null
export function parseFloat(nbr: string | number | nil, defaultValue: any = null): number | null {
    if(nbr == null || nbr === '') return defaultValue
    const val = globalThis.parseFloat(nbr as string)
    return Number.isFinite(val) ? val : defaultValue
}

export function fullWide(n: number|string):string {
    n = parseFloat(n,0)
    try {
        return n.toLocaleString('en-US', {useGrouping: false, maximumFractionDigits: 20})
    } catch {
        return n.toFixed(14).replace(/\.?0+$/,'')
    }
}


// yr test -t fullWide --silent=false assets/scripts/util/math.test.ts
// export function fullWide(n: number|string):string {
//     return String(n)
//         .replace(/^(\d+(?:\.\d*)?|\.\d+)e(-?\d+)$/, (_,left,right) => {
//             const e = Number(right)
//             let [whole,frac] = left.split('.') as [string,string|undefined]
//             whole = whole.replace(/^0+/,'')
//             whole ||= '0'
//             frac = frac ? frac.replace(/0+$/,'') : ''
//             if(e < 0) {
//                 return whole + '.' + frac.padStart(-e,'0')
//             }
//             let ret = whole.padEnd(e+1,'0')
//             if(frac) {
//                 ret += '.' + frac
//             }
//             return ret
//         })
// }


export function round(value: string | number, decimalDigits = 0): number {
    // const mult = Math.pow(10,decimalDigits|0);
    // return Math.round(parseFloat(value,0)*mult)/mult;
    // https://stackoverflow.com/a/23560569/65387
    return +(`${Math.round(+(`${fullWide(value)}e${decimalDigits}`))}e${-decimalDigits}`)

}

export type RoundMode = 'UP' | 'DOWN' | 'NEAREST'
const ROUNDING_FUNCS: Record<string, (n: number) => number> = {
    UP: Math.ceil,
    DOWN: Math.floor,
    NEAREST: Math.round,
}

export function roundNearest(value: StringNum, step: StringNum, mode: RoundMode = 'NEAREST', offset = 0) {
    value = parseFloat(value, 0)
    step = Math.abs(parseFloat(step, 0))

    if(step < 0.0001) {
        return value
    }

    const func = ROUNDING_FUNCS[mode.toUpperCase()] || Math.round

    return func((value - offset) / step) * step + offset
}

export function floatFix(value: number, decimalDigits = 3) {
    return value.toFixed(decimalDigits).replace(/\.?0+$/, '')
}

export function clamp(val: number, min: number, max: number) {
    if(val < min) {
        return min
    }
    if(val > max) {
        return max
    }
    return val
}

/**
 * Divide numbers and get quotient and remainder
 * @param n The number being divided.
 * @param d The number that `n` is being divided by.
 */
export function divQR(n: number, d: number): [quotient: number, remainder: number] {
    const q = Math.trunc(n / d)
    const r = n - (q * d)
    return [q, r]
}

/**
 * Split a number into whole and fractional parts.
 */
export function wholeFrac(n: number): [whole: number, frac: number] {
    const w = Math.trunc(n)
    const f = n - w
    return [w, f]
}
