import ErrorBoundary from '../components/ErrorBoundary'
import React, {ReactNode, Suspense} from 'react'
import {QueryClientProvider,} from 'react-query'
import {queryClient} from '../helpers/query-client'
import LoadingDots from '../components/LoadingDots'

interface PageFragmentProps {
    children: ReactNode
}

export default function PageFragment(props: PageFragmentProps) {
    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <Suspense fallback={<LoadingDots center height={88}/>}>
                    {props.children}
                </Suspense>
            </ErrorBoundary>
        </QueryClientProvider>
    )
}
