import {QueryClient} from 'react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            // cacheTime does nothing as long as a query is still in used. It only kicks in as soon as the query
            // becomes unused. After the time has passed, data will be "garbage collected" to avoid the cache from growing.
            cacheTime: 1000 * 60 * 60 * 8,
            refetchInterval: 1000 * 60 * 60,
            retry: 1,
            refetchOnMount: 'always',
        }
    }
})
