import React, {ReactNode} from 'react'
import {ChildrenOfType} from '../helpers/react-helpers'

type LeftOrRightProps = {
    children: ReactNode
}

export function Left({children}: LeftOrRightProps) {
    return <div className="button-row-left">{children}</div>
}

export function Right({children}: LeftOrRightProps) {
    return <div className="button-row-right">{children}</div>
}

type ButtonRowProps = {
    children: ChildrenOfType<typeof Left | typeof Right>
}

export default function ButtonRow({children}: ButtonRowProps) {
    return (
        <div className="button-row-flex">
            {children}
        </div>
    )
}

ButtonRow.Left = Left
ButtonRow.Right = Right
