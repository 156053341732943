export function isWhitespace(str: string): boolean {
    return /^\s*$/.test(str)
}

/**
 * Checks if the object is empty user input.
 * i.e. the object has to deeply contain something other than just whitespace.
 */
export function isEmptyInput(obj: any): boolean {
    if(obj == null) return true
    if(typeof obj === 'number') return false
    if(typeof obj === 'string') return /^\s*$/.test(obj)
    if(Array.isArray(obj)) return obj.every(v => isEmptyInput(v))
    if(obj instanceof Set || obj instanceof Map) return obj.size === 0
    if(obj instanceof Date) return Number.isNaN(obj.valueOf())
    return Object.values(obj).every(v => isEmptyInput(v))
}
