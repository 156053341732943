import {ComponentPropsWithClass, withClass} from '../helpers/react-helpers'
import {FC} from 'react'
import cn from 'classnames'
import {useTestId} from '../contexts/testid'

interface ButtonProps extends ComponentPropsWithClass<'button'> {
    width?: string|number
    testId?: string
}

export const Button: FC<ButtonProps> = ({width,style,className,testId,...props}) => (
    <button className={cn('btn',className)} data-testid={useTestId(testId)} style={{width,minWidth:width,...style}} {...props}/>
)

export default Button
